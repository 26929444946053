<template>
  <section class="py-5 px-2">
    <return-materials class="mx-5"></return-materials>
  </section>
</template>

<script>
import ReturnMaterials from "@/components/proyectos/lugarInstalacion/lugarSolicitudMaterial/returnMaterials/index.vue";
export default {
  components: {
    ReturnMaterials,
  },
};
</script>

<style></style>
